/* eslint-disable camelcase */
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Carousel from '../../Carousel'
import { slugify, decodeEntities } from '../../../utils/helpers'
import { Image } from '../../Image'
import './TestimonialCarousel.scss'

export const TestimonialItem = props => {
  const { slide, className } = props

  return (
    <div className={className}>
      <div className="wrap">
        <div className="inner">
          <div className="image-part">
            {slide?.acf.image && (
              <div className="image">
                <div className="inner-image">
                  {slide?.acf.image?.localFile ? (
                    <Image
                      src={slide?.acf.image}
                      className="testimonial-carousel--image"
                      fadeIn
                    />
                  ) : (
                    <img
                      className="testimonial-carousel--image"
                      src={slide?.acf.image.source_url}
                      alt={slide.title}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="content-part">
            <div className="logo">
              {slide?.acf.logo.source_url && (
                <div className="inner-logo">
                  <img
                    className="testimonial-carousel--logo"
                    src={slide?.acf.logo.source_url}
                    alt={`${slide.title} logo`}
                  />
                </div>
              )}
            </div>

            {slide && (
              <div className="content">
                <p
                  dangerouslySetInnerHTML={{
                    __html: decodeEntities(slide.content),
                  }}
                />

                {slide?.acf.name && (
                  <div className="testimonial-name-and-title">
                    <span className="testimonial-name">
                      {decodeEntities(slide?.acf.name)}
                    </span>
                    <br />
                    <span className="testimonial-title">
                      <strong>{decodeEntities(slide.title)}</strong>
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const ThisSlider = props => {
  const { testimonials, title } = props
  const settings = {
    container: `${slugify(title)}-slug`,
    nav: true,
    controls: false,
    mouseDrag: true,
    loop: false,
    items: 1,
    autoHeight: true,
  }

  return (
    <div className="carousel-wrap">
      <Carousel settings={settings}>
        {testimonials
          .filter(slide => slide?.acf?.image)
          .map(
            (slide, slideCount) =>
              slideCount < 8 && (
                <TestimonialItem
                  key={`testimonial-${slideCount}`}
                  slide={slide}
                  className="slide"
                />
              )
          )}
      </Carousel>
    </div>
  )
}

export const TestimonialPass = props => {
  const { title, text, data } = props
  const { allWordpressWpTestimonials } = data
  const { nodes: posts } = allWordpressWpTestimonials
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }

  return (
    <section className="testimonial-carousel">
      {/* {title && (
        <span className="testimonial-carousel--title">
          <span className="header-background">{decodeEntities(title)}</span>
        </span>
      )} */}
      {/* {text && (
        <p className="testimonial-carousel--text">{decodeEntities(text)}</p>
      )} */}
      <ThisSlider testimonials={posts} title={title} text={text} />
    </section>
  )
}

export const TestimonialCarousel = props => {
  return (
    <StaticQuery
      // Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          allWordpressWpTestimonials {
            nodes {
              content
              slug
              title
              acf {
                logo {
                  source_url
                }
                image {
                  source_url
                  localFile {
                    childImageSharp {
                      id
                      fluid(maxWidth: 600, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                name
                position
              }
            }
          }
        }
      `}
      render={data => <TestimonialPass {...props} data={data} />}
    />
  )
}

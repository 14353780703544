import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '../../Image'
import Carousel from '../../Carousel'
import './FeaturedProject.scss'

export const FeaturedProject = props => {
  const { title, name, images, subheader, content, cta } = props

  const settings = {
    container: `featured-gallery`,
    nav: true,
    controls: true,
    mouseDrag: true,
    items: 1,
    loop: false,
  }
  return !props ? (
    <p>Featured Project component</p>
  ) : (
    <section className="featured-project">
      <div className="featured-project-wrapper">
        <div className="featured-project-inner">
          <div className="featured-project--title">{title}</div>
          <div className="featured-project--name">{name}</div>
          <div className="featured-project--gallery">
            {images && (
              <Carousel settings={settings}>
                {images.map((image, index) => (
                  <div className="slide" key={index}>
                    {image.localFile ? (
                      <Image
                        src={image}
                        alt="decorative image"
                        className="featured-project--gallery-image"
                      />
                    ) : (
                      <img
                        className="featured-project--gallery-image"
                        src={image.source_url}
                        alt=""
                      />
                    )}
                  </div>
                ))}
              </Carousel>
            )}
          </div>
          {subheader && (
            <div className="featured-project--subheader">{subheader}</div>
          )}
          <div
            className="featured-project--content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
          {cta && (
            <div className="featured-project--cta">
              <p>
                <a className="action" href={cta.url}>
                  {cta.title}
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

FeaturedProject.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  subheader: PropTypes.string,
  content: PropTypes.string,
  images: PropTypes.array,
}

FeaturedProject.defaultProps = {
  title: 'Default project of the year',
  name: 'Default Project Name',
  content: '<p>Default text content for the FeaturedProject Component</p>',
  images: [
    {
      localFile: {},
      source_url: '/wp-content/uploads/2020/12/KrispyKreme_4.jpg',
    },
    {
      localFile: {},
      source_url: '/wp-content/uploads/2020/12/KrispyKreme_4.jpg',
    },
    {
      localFile: {},
      source_url: '/wp-content/uploads/2020/12/KrispyKreme_4.jpg',
    },
  ],
}

import React from 'react'
import Carousel from '../../Carousel'
import { slugify } from '../../../utils/helpers'
import './LogoFeed.scss'

function Gallery(props) {
  const title = slugify(props.title)
  if (props.gallery == null) return null
  const galleryArray = props.gallery
  const isSlider = props.isSlider || false

  const sliderSettings = {
    slideBy: 1,
    nav: false,
    controls: false,
    mouseDrag: true,
    responsive: {
      2600: {
        items: 8,
      },
      1680: {
        items: 6,
      },
      1080: {
        items: 4,
      },
      700: {
        items: 3,
      },
      310: {
        items: 2,
      },
    },
    speed: 600,
    autoplay: true,
    loop: true,
    lazyLoad: false,
    container: `${title}`,
  }
  if (isSlider) {
    return (
      <Carousel settings={sliderSettings}>
        {galleryArray.map((item, index) => {
          return <SlideItem className="slide-item" item={item} key={index} />
        })}
      </Carousel>
    )
  }
  return galleryArray.map((item, index) => {
    return <SlideItem item={item} key={index} className="item" />
  })
}

const SlideItem = ({ item, className = '' }) => {
  // use RegEx to remove html tags
  const htmlTagsRegex = /(<([^>]+)>)/gi
  const caseStudyUrl = item.caption.replace(htmlTagsRegex, '') || ''
  const isCaseStudyUrl = caseStudyUrl !== ''

  let itemUrl
  if (!item.source_url) {
    if (item.url) {
      itemUrl = item.url
    }
  } else {
    itemUrl = item.source_url
  }

  return (
    <div className={className}>
      {isCaseStudyUrl ? (
        <a href={caseStudyUrl}>
          <img src={itemUrl} alt={item.title ? item.title : ''} />
        </a>
      ) : (
        <img src={itemUrl} alt={item.title ? item.title : ''} />
      )}
    </div>
  )
}

export const LogoFeed = props => {
  const {
    LogoGallery: gallery,
    title,
    isNarrow,
    backgroundStyle = 'primary',
    isSlider = true,
  } = props
  return (
    <section
      className={`
        logo-feed 
        ${isNarrow ? 'logo-narrow' : ''}
        ${backgroundStyle && `background-${backgroundStyle}`}
        ${isSlider ? `logo-slider` : ''}
      `}
    >
      <div className="title">
        <h2>{title}</h2>
      </div>
      <div className="inner">
        <div className="logo-wrapper">
          <Gallery gallery={gallery} isSlider={isSlider} title={title} />
        </div>
      </div>
    </section>
  )
}
